import { Link, useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image"

const Header = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "icon.png" }) {
        childImageSharp {
          fixed(height: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <Link
        to="/"
        style={{
          color: `white`,
          textDecoration: `none`,
        }}
      >
        <Img
          fixed={data.logo.childImageSharp.fixed}
          className="navbar-brand-img"
          alt="logo"
        />
        <h1 style={{ margin: 0 }} className="navbar-brand">
          {siteTitle}
        </h1>
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbar"
        aria-controls="navbar"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbar">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item dropdown">
            <Link
              to="#"
              className="nav-link dropdown-toggle"
              id="seasonsDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Seasons
            </Link>
            <div className="dropdown-menu" aria-labelledby="seasonsDropdown">
              <Link to="/season/1920" className="dropdown-item">
                2019/20
              </Link>
              <Link to="/season/1819" className="dropdown-item">
                2018/19
              </Link>
              <Link to="/season/1718" className="dropdown-item">
                2017/18
              </Link>
              <Link to="/season/1617" className="dropdown-item">
                2016/17
              </Link>
              <Link to="/season/1516" className="dropdown-item">
                2015/16
              </Link>
              <Link to="/season/1415" className="dropdown-item">
                2014/15
              </Link>
              <Link to="/season/1314" className="dropdown-item">
                2013/14
              </Link>
              <Link to="/season/1213" className="dropdown-item">
                2012/13
              </Link>
              <Link to="/season/1112" className="dropdown-item">
                2011/12
              </Link>
              <Link to="/season/1011" className="dropdown-item">
                2010/11
              </Link>
            </div>
          </li>
          <li className="nav-item dropdown">
            <Link
              to="#"
              className="nav-link dropdown-toggle"
              id="compsDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Competitions
            </Link>
            <div className="dropdown-menu" aria-labelledby="compsDropdown">
              <Link to="/competition/nhl" className="dropdown-item">
                National Hockey League
              </Link>
              <Link to="/competition/ahl" className="dropdown-item">
                American Hockey League
              </Link>
              <Link to="/competition/echl" className="dropdown-item">
                East Coast Hockey League
              </Link>
              <Link to="/competition/chl" className="dropdown-item">
                Champions Hockey League
              </Link>
              <Link to="/competition/wc" className="dropdown-item">
                World Championship
              </Link>
              <Link to="/competition/cc" className="dropdown-item">
                Continental Cup
              </Link>
              <Link to="/competition/khl" className="dropdown-item">
                Kontinental Hockey League
              </Link>
              <div className="dropdown-divider"></div>
              <Link to="/" className="dropdown-item">
                <small>For more competitions go to the Home page</small>
              </Link>
            </div>
          </li>
          <li className="nav-item dropdown">
            <Link to="/about" className="nav-link">
              About
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
